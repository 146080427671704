import Sidebar from "./components/Sidebar";
import { useState, useEffect, useRef, createContext } from "react";
import DashboardRoutes from "./routes/DashboardRoutes";
import { Routes, Route, useNavigate } from "react-router-dom";
import AuthRouter from "./routes/AuthRouter";
import { server, config } from "./env";
import axios from "axios";
import PageLoader from "./components/PageLoader";
import "./App.css";

export const UserContext = createContext();

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const sidebarRef = useRef();
  const [user, setUser] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    checkUserLogin();
  }, []);

  useEffect(() => {
    if (user && user.role === "staff") {
      navigate("/invoices");
    } else {
    }
  }, [user]);

  const checkUserLogin = async () => {
    await axios(server + "/api/v1/auth/admin-access", config)
      .then((rsp) => {
        setUser(rsp.data);
        setIsLogin(true);
        setIsAllLoaded(true);
      })
      .catch((err) => {
        setIsLogin(false);
        setIsAllLoaded(true);
      });
  };

  return isAllLoaded ? (
    isLogin ? (
      <UserContext.Provider value={{ user, setUser }}>
        <div className="nk-app-root">
          <div className="nk-main ">
            <Sidebar sidebarRef={sidebarRef} />
            <Routes>
              <Route path="*" element={<DashboardRoutes sidebarRef={sidebarRef} />} />
            </Routes>
          </div>
        </div>
      </UserContext.Provider>
    ) : (
      <Routes>
        <Route path="*" element={<AuthRouter />} />
      </Routes>
    )
  ) : (
    <PageLoader />
  );
}

export default App;
