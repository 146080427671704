import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { BarChart } from "../tools/Chart";

const Dashboard = (props) => {
  const [OrderStats, setOrderStats] = useState({});
  const [usersStats, setUsersStats] = useState({});
  const [revenueStats, setRevenueStats] = useState({});
  const [invoiceStats, setInvoiceStats] = useState({});
  const [labelTypesData, setLabelTypesData] = useState([]);

  // total labeltypes stats
  const [totalToday, setTotalToday] = useState(0);
  const [totalYesterday, setTotalYesterday] = useState(0);
  const [totalThisWeek, setTotalThisWeek] = useState(0);
  const [totalThisMonth, setTotalThisMonth] = useState(0);
  const [totalAllTime, setTotalAllTime] = useState(0);

  // dashboard stats
  const getOrdersStat = async () => {
    await axios
      .get(server + "/api/v1/dashboard/orders-stats", config)
      .then((res) => {
        setOrderStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUsersStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/users", config)
      .then((res) => {
        setUsersStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // barchart data
  const getOrdersBarChart = async () => {
    await axios
      .get(server + "/api/v1/dashboard/revenue-graph", config)
      .then((res) => {
        const e = document.getElementById("order_chart");
        var canvas = document.createElement("CANVAS");
        while (e.firstChild) {
          e.removeChild(e.firstChild);
        }
        e.appendChild(canvas);
        BarChart(canvas, res.data, "blue", "Revenue");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getOrdersBarStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/revenue-stats", config)
      .then((res) => {
        setRevenueStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInvoiceStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/deposit-stats", config)
      .then((res) => {
        setInvoiceStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readLabelTypeStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/type-stats", config)
      .then((res) => {
        setLabelTypesData(res.data.labelTypesData);

        res.data.labelTypesData.map((labelType) => {
          setTotalToday((prev) => prev + labelType.todayOrdersCount);
          setTotalYesterday((prev) => prev + labelType.yesterdayOrdersCount);
          setTotalThisWeek((prev) => prev + labelType.thisWeekOrdersCount);
          setTotalThisMonth((prev) => prev + labelType.thisMonthOrdersCount);
          setTotalAllTime((prev) => prev + labelType.allTimeOrdersCount);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // call all the functions at once
  // const callAll = async () => {
  //   // promise all
  //   await Promise.all([getOrdersStat(), getUsersStats(), getOrdersBarChart()]);
  // };

  useEffect(() => {
    getOrdersStat();
    getUsersStats();
    getOrdersBarChart();
    getOrdersBarStats();
    getInvoiceStats();
    readLabelTypeStats();
  }, []);

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Dashboard</h3>
                  </div>
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-12 col-md-6 col-lg-3">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Orders</h5>
                            <h6 className="py-2">
                              {OrderStats.allTimeOrdersCount
                                ? OrderStats.allTimeOrdersCount
                                : 0}
                            </h6>
                          </div>
                          <div className="flex flex-column  ">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="text text-primary icon ni ni-box-view-fill"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Users</h5>
                            <h6 className="py-2">
                              {usersStats.allTimeUsersCount
                                ? usersStats.allTimeUsersCount
                                : 0}
                            </h6>
                          </div>
                          <div className="flex flex-column  ">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="text text-primary icon ni ni-users"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Revenue</h5>
                            <h6 className="py-2">
                              {revenueStats.allTimeRevenue
                                ? revenueStats.allTimeRevenue
                                : 0}
                            </h6>
                          </div>
                          <div className="flex flex-column  ">
                            <em
                              style={{ fontSize: "2.5rem" }}
                              class="text text-primary icon ni ni-sign-usd"
                            ></em>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div
                      className="card h-100"
                      style={{ borderRadius: "0.8rem" }}
                    >
                      <div className="card-inner">
                        <div className="d-flex flex-wrap justify-content-between">
                          <div className="flex flex-column">
                            <h5 className="text-gray">Total Deposits</h5>
                            <h6 className="py-2">
                              {invoiceStats.allTimeInvoicesAmount
                                ?.allTimeInvoicesAmount
                                ? invoiceStats.allTimeInvoicesAmount
                                    .allTimeInvoicesAmount
                                : 0}
                            </h6>
                          </div>
                          <div className="d-flex flex-wrap p-1">
                            <div className="d-flex flex-column mr-2">
                              <h6 className="fs-14px text-primary">Cashapp </h6>
                              <h6 className="py-2">
                                {invoiceStats.allTimeInvoicesAmount?.cashApp
                                  ? invoiceStats.allTimeInvoicesAmount.cashApp
                                  : 0}
                              </h6>
                            </div>
                            <div className="d-flex flex-column ">
                              <h6 className="fs-14px text-primary">
                                Coinbase{" "}
                              </h6>
                              <h6 className="py-2">
                                {invoiceStats.allTimeInvoicesAmount?.coinbase
                                  ? invoiceStats.allTimeInvoicesAmount.coinbase
                                  : 0}
                              </h6>
                            </div>
                            <div className="d-flex flex-column ">
                              <h6 className="fs-14px text-primary">Hoodpay </h6>
                              <h6 className="py-2">
                                {invoiceStats.allTimeInvoicesAmount?.hoodpay
                                  ? invoiceStats.allTimeInvoicesAmount.hoodpay
                                  : 0}
                              </h6>
                            </div>
                            {/* <em style={{ fontSize: "2.5rem" }} class="text text-primary icon ni ni-sign-usd"></em> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block ">
                <div className="card" style={{ borderRadius: "0.8rem" }}>
                  <div className="card-inner">
                    <div className="d-flex flex-wrap">
                      <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
                        <h5>Orders:</h5>
                      </div>
                      <div className="col-12 col-md-11  px-md-2 px-0 d-flex flex-wrap flex-fill mt-md-2 mt-0 mt-lg-0">
                        <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Today</h6>
                                  <h5 className="py-2">
                                    {OrderStats.todayOrdersCount
                                      ? OrderStats.todayOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-cart"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Week</h6>
                                  <h5 className="py-2">
                                    {OrderStats.thisWeekOrdersCount
                                      ? OrderStats.thisWeekOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-cart"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Month</h6>
                                  <h5 className="py-2">
                                    {OrderStats.thisMonthOrdersCount
                                      ? OrderStats.thisMonthOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-cart"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Custom</h6>
                                  <h5 className="py-2">
                                    {OrderStats.customOrders
                                      ? OrderStats.customOrders
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-cart"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap mt-5">
                      <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
                        <h5>Users:</h5>
                      </div>
                      <div className="col-12 col-md-11  px-md-2 px-0 d-flex flex-wrap flex-fill mt-md-2 mt-0 mt-lg-0">
                        <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Today</h6>
                                  <h5 className="py-2">
                                    {usersStats.todayOrdersCount
                                      ? usersStats.todayOrdersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-user-list"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Week</h6>
                                  <h5 className="py-2">
                                    {usersStats.thisWeekUsersCount
                                      ? usersStats.thisWeekUsersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-user-list"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Month</h6>
                                  <h5 className="py-2">
                                    {usersStats.thisMonthUsersCount
                                      ? usersStats.thisMonthUsersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-user-list"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Custom</h6>
                                  <h5 className="py-2">
                                    {usersStats.customUsersCount
                                      ? usersStats.customUsersCount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-user-list"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap mt-5">
                      <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
                        <h5>Revenue:</h5>
                      </div>
                      <div className="col-12 col-md-11  px-md-2 px-0  d-flex flex-wrap flex-fill mt-md-2 mt-0 mt-lg-0">
                        <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Today</h6>
                                  <h5 className="py-2">
                                    {revenueStats.todayRevenue
                                      ? revenueStats.todayRevenue
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-sign-usdc"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Week</h6>
                                  <h5 className="py-2">
                                    {revenueStats.thisWeekRevenue
                                      ? revenueStats.thisWeekRevenue
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-sign-usdc"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Month</h6>
                                  <h5 className="py-2">
                                    {revenueStats.thisMonthRevenue
                                      ? revenueStats.thisMonthRevenue
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-sign-usdc"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Custom</h6>
                                  <h5 className="py-2">
                                    {revenueStats.customRevenue
                                      ? revenueStats.customRevenue
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-sign-usdc"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap mt-5">
                      <div className="col-12 col-md-1 d-flex justify-content-center align-items-center">
                        <h5>Deposits:</h5>
                      </div>
                      <div className="col-12 col-md-11  px-md-2 px-0  d-flex flex-wrap flex-fill mt-md-2 mt-0 mt-lg-0">
                        <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Today</h6>
                                  <h5 className="py-2">
                                    {invoiceStats.todayInvoicesAmount
                                      ?.todayInvoicesAmount
                                      ? invoiceStats.todayInvoicesAmount
                                          ?.todayInvoicesAmount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <div className="d-flex flex-wrap p-1">
                                    <div className="d-flex flex-column mr-2">
                                      <h6 className="fs-14px text-primary">
                                        Cashapp{" "}
                                      </h6>
                                      <h6 className="py-2">
                                        {invoiceStats.todayInvoicesAmount
                                          ?.cashApp
                                          ? invoiceStats.todayInvoicesAmount
                                              .cashApp
                                          : 0}
                                      </h6>
                                    </div>
                                    <div className="d-flex flex-column ">
                                      <h6 className="fs-14px text-primary">
                                        Coinbase{" "}
                                      </h6>
                                      <h6 className="py-2">
                                        {invoiceStats.todayInvoicesAmount
                                          ?.coinbase
                                          ? invoiceStats.todayInvoicesAmount
                                              .coinbase
                                          : 0}
                                      </h6>
                                    </div>
                                    <div className="d-flex flex-column ">
                                      <h6 className="fs-14px text-primary">
                                        Hoodpay{" "}
                                      </h6>
                                      <h6 className="py-2">
                                        {invoiceStats.todayInvoicesAmount
                                          ?.hoodpay
                                          ? invoiceStats.todayInvoicesAmount
                                              .hoodpay
                                          : 0}
                                      </h6>
                                    </div>

                                    {/* <em style={{ fontSize: "2.5rem" }} class="text text-primary icon ni ni-sign-usd"></em> */}
                                  </div>
                                  {/* <em style={{ fontSize: "2.5rem" }} class="icon ni ni-sign-steem-alt"></em> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2 mt-md-0 mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Week</h6>
                                  <h5 className="py-2">
                                    {" "}
                                    {invoiceStats.thisWeekInvoicesAmount
                                      ?.thisWeekInvoicesAmount
                                      ? invoiceStats.thisWeekInvoicesAmount
                                          ?.thisWeekInvoicesAmount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <div className="flex flex-column  ">
                                    <div className="d-flex flex-wrap p-1">
                                      <div className="d-flex flex-column mr-2">
                                        <h6 className="fs-14px text-primary">
                                          Cashapp{" "}
                                        </h6>
                                        <h6 className="py-2">
                                          {invoiceStats.thisWeekInvoicesAmount
                                            ?.cashApp
                                            ? invoiceStats
                                                .thisWeekInvoicesAmount?.cashApp
                                            : 0}
                                        </h6>
                                      </div>
                                      <div className="d-flex flex-column ">
                                        <h6 className="fs-14px text-primary">
                                          Coinbase{" "}
                                        </h6>
                                        <h6 className="py-2">
                                          {invoiceStats.thisWeekInvoicesAmount
                                            ?.coinbase
                                            ? invoiceStats
                                                .thisWeekInvoicesAmount
                                                ?.coinbase
                                            : 0}
                                        </h6>
                                      </div>
                                      <div className="d-flex flex-column ">
                                        <h6 className="fs-14px text-primary">
                                          Hoodpay{" "}
                                        </h6>
                                        <h6 className="py-2">
                                          {invoiceStats.thisWeekInvoicesAmount
                                            ?.hoodpay
                                            ? invoiceStats
                                                .thisWeekInvoicesAmount?.hoodpay
                                            : 0}
                                        </h6>
                                      </div>

                                      {/* <em style={{ fontSize: "2.5rem" }} class="text text-primary icon ni ni-sign-usd"></em> */}
                                    </div>
                                    {/* <em style={{ fontSize: "2.5rem" }} class="icon ni ni-sign-steem-alt"></em> */}
                                  </div>
                                  {/* <em style={{ fontSize: "2.5rem" }} class="icon ni ni-sign-steem-alt"></em> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Month</h6>
                                  <h5 className="py-2">
                                    {" "}
                                    {invoiceStats.thisMonthInvoicesAmount
                                      ?.thisMonthInvoicesAmount
                                      ? invoiceStats.thisMonthInvoicesAmount
                                          ?.thisMonthInvoicesAmount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <div className="flex flex-column  ">
                                    <div className="d-flex flex-wrap p-1">
                                      <div className="d-flex flex-column mr-2">
                                        <h6 className="fs-14px text-primary">
                                          Cashapp{" "}
                                        </h6>
                                        <h6 className="py-2">
                                          {invoiceStats.thisMonthInvoicesAmount
                                            ?.cashApp
                                            ? invoiceStats
                                                .thisMonthInvoicesAmount
                                                ?.cashApp
                                            : 0}
                                        </h6>
                                      </div>
                                      <div className="d-flex flex-column ">
                                        <h6 className="fs-14px text-primary">
                                          Coinbase{" "}
                                        </h6>
                                        <h6 className="py-2">
                                          {invoiceStats.thisMonthInvoicesAmount
                                            ?.coinbase
                                            ? invoiceStats
                                                .thisMonthInvoicesAmount
                                                ?.coinbase
                                            : 0}
                                        </h6>
                                      </div>

                                      <div className="d-flex flex-column ">
                                        <h6 className="fs-14px text-primary">
                                          Hoodpay{" "}
                                        </h6>
                                        <h6 className="py-2">
                                          {invoiceStats.thisMonthInvoicesAmount
                                            ?.hoodpay
                                            ? invoiceStats
                                                .thisMonthInvoicesAmount
                                                ?.hoodpay
                                            : 0}
                                        </h6>
                                      </div>

                                      {/* <em style={{ fontSize: "2.5rem" }} class="text text-primary icon ni ni-sign-usd"></em> */}
                                    </div>
                                    {/* <em style={{ fontSize: "2.5rem" }} class="icon ni ni-sign-steem-alt"></em> */}
                                  </div>
                                  {/* <em style={{ fontSize: "2.5rem" }} class="icon ni ni-sign-steem-alt"></em> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3 mt-2  mt-lg-0">
                          <div
                            className="card"
                            style={{ borderRadius: "0.8rem" }}
                          >
                            <div className="card-inner">
                              <div className="d-flex flex-row justify-content-between">
                                <div className="flex flex-column">
                                  <h6 className="text-gray">Custom</h6>
                                  <h5 className="py-2">
                                    {invoiceStats.customInvoicesAmount
                                      ? invoiceStats.customInvoicesAmount
                                      : 0}
                                  </h5>
                                </div>
                                <div className="flex flex-column  ">
                                  <em
                                    style={{ fontSize: "2.5rem" }}
                                    class="icon ni ni-sign-steem-alt"
                                  ></em>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* type stats */}
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="card card-bordered h-100">
                      <div className="card-header">
                        <h5 className="card-title">Type Statistics</h5>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table ">
                            <thead>
                              <tr className="tb-tnx-head">
                                <th className="tb-tnx-id">
                                  <span className="overline-title">Type</span>
                                </th>
                                <th className="tb-tnx-info">
                                  <span className="overline-title">today</span>
                                </th>{" "}
                                <th className="tb-tnx-info">
                                  <span className="overline-title">
                                    yesterday
                                  </span>
                                </th>{" "}
                                <th className="tb-tnx-info">
                                  <span className="overline-title">Week</span>
                                </th>{" "}
                                <th className="tb-tnx-info">
                                  <span className="overline-title">Month</span>
                                </th>{" "}
                                <th className="tb-tnx-info">
                                  <span className="overline-title">
                                    AllTime
                                  </span>
                                </th>{" "}
                              </tr>
                            </thead>
                            <tbody>
                              {labelTypesData.map((labelType, index) => (
                                <tr key={index}>
                                  <td className="tb-tnx-id">
                                    <span className="title">
                                      {labelType.labeltype?.name}
                                    </span>
                                  </td>
                                  <td className="tb-tnx-info">
                                    <span className="title">
                                      {labelType.todayOrdersCount}
                                    </span>
                                  </td>{" "}
                                  <td className="tb-tnx-info">
                                    <span className="title">
                                      {labelType.yesterdayOrdersCount}
                                    </span>
                                  </td>{" "}
                                  <td className="tb-tnx-info">
                                    <span className="title">
                                      {labelType.thisWeekOrdersCount}
                                    </span>
                                  </td>{" "}
                                  <td className="tb-tnx-info">
                                    <span className="title">
                                      {labelType.thisMonthOrdersCount}
                                    </span>
                                  </td>{" "}
                                  <td className="tb-tnx-info">
                                    <span className="title">
                                      {labelType.allTimeOrdersCount}
                                    </span>
                                  </td>{" "}
                                </tr>
                              ))}
                              <tr>
                                <td className="tb-tnx-id">
                                  <span className="title">Total</span>
                                </td>
                                <td className="tb-tnx-info">
                                  <span className="title">{totalToday}</span>
                                </td>{" "}
                                <td className="tb-tnx-info">
                                  <span className="title">
                                    {totalYesterday}
                                  </span>
                                </td>{" "}
                                <td className="tb-tnx-info">
                                  <span className="title">{totalThisWeek}</span>
                                </td>{" "}
                                <td className="tb-tnx-info">
                                  <span className="title">
                                    {totalThisMonth}
                                  </span>
                                </td>{" "}
                                <td className="tb-tnx-info">
                                  <span className="title">{totalAllTime}</span>
                                </td>{" "}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-xxl-12">
                    <div className="card card-bordered h-100">
                      <div className="card-header">
                        <h5 className="card-title">Revenue Graph</h5>
                      </div>
                      <div className="card-body">
                        <div id="order_chart"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
