import React, { useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config, downloadConfig } from "../env";
import { useEffect } from "react";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Orders = (props) => {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [order_status, setOrder_status] = useState("");
  const [url, setUrl] = useState("/api/v1/orders/read");
  const [orderContent, setOrderContent] = useState({});

  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    getOrders();
  }, [page, sort, filter, limit]);

  const getOrders = async (search) => {
    var link = "";

    if (search) {
      link = url + "?page=" + page + "&limit=" + limit + "&search=" + search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
      // "&sort=" +
      // sort +
      // "&status=" +
      // filter;
    }

    await axios
      .get(server + link, config)
      .then((res) => {
        setOrders(res.data.orders);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateOrder = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = new FormData();
    params.append("pdf", e.target.pdf.files[0]);

    await axios
      .post(server + "/admin/updateOrder/" + order._id, params, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getOrders();
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
      e.target.reset();
    }, 3000);
  };

  const exportCSV = () => {
    // convert data into csv

    // write heading
    let csv = "";
    csv +=
      "Order ID,Status, fromName, fromEmail,fromCompany, fromAddress, fromAddress2, fromAddress3, fromZipCode,fromCity , fromState, fromCountry, toName, toEmail,toCompany, toAddress, toAddress2, toAddress3, toZipCode,toCity , toState, toCountry,product, dropoff, contentSummary, value,weight1 , length1 , width1 , height1, weight2 , length2 , width2 , height2, weight3 , length3 , width3 , height3, \n";
    // write data
    orders.forEach((order) => {
      csv += `${order._id} ,${order.status}, ${order.content.fromName} , ${order.content.fromEmail} , ${order.content.fromCompany} , ${order.content.fromAddress} , ${order.content.fromAddress2} , ${order.content.fromAddress3} , ${order.content.fromZipCode} , ${order.content.fromCity} , ${order.content.fromState} , ${order.content.fromCountry} , ${order.content.toName} , ${order.content.toEmail} , ${order.content.toCompany} , ${order.content.toAddress} , ${order.content.toAddress2} , ${order.content.toAddress3} , ${order.content.toZipCode} , ${order.content.toCity} , ${order.content.toState} , ${order.content.toCountry} , ${order.content.product} , ${order.content.dropoff} , ${order.content.content_summary}, ${order.content.value} , ${order.content.weight1},${order.content.length1},${order.content.width1},${order.content.height1},${order.content.weight2},${order.content.length2},${order.content.width2},${order.content.height2},${order.content.weight3},${order.content.length3},${order.content.width3},${order.content.height3}\n`;
    });

    // download csv file
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "orders.csv";
    link.click();
  };

  const cancelOrder = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    await axios
      .put(server + "/admin/cancelOrder/" + order._id, {}, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getOrders();
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const handleLimitChange = (e) => {
    console.log(e.target.value);
    setLimit(e.target.value);
  };

  const downloadPdf = async (id) => {
    await axios
      .get(`${server}/api/v1/orders/download/${id}`, downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "order.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const downloadPdfAll = async (e) => {
    e.preventDefault();

    const params = {
      orders: selectedOrders,
    };

    await axios
      .post(
        `${server}/api/v1/orders/download-selected-pdf`,
        params,
        downloadConfig
      )
      .then((response) => {
        //  download zip file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });
  };
  const downloadZipAll = async (e) => {
    e.preventDefault();

    const params = {
      orders: selectedOrders,
    };

    await axios
      .post(
        `${server}/api/v1/orders/download-selected-zip`,
        params,
        downloadConfig
      )
      .then((response) => {
        //  download zip file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Orders</h3>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-lg-4 ">
                          <div className="d-flex flex-column flex-lg-row justify-content-between  align-items-center">
                            {/* .search */}
                            <div className="col-lg-4 col-12 mt-3">
                              <div className="form-group mr-3 ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  onChange={(e) => {
                                    getOrders(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-12 mt-3 d-flex justify-content-end">
                              {selectedOrders.length > 0 && (
                                <div className="d-flex flex-row">
                                  <button
                                    className="btn bg-primary mr-2 text-white"
                                    onClick={downloadPdfAll}
                                  >
                                    Download PDF
                                  </button>
                                  <button
                                    className="btn btn-primary mr-2 text-white"
                                    onClick={downloadZipAll}
                                  >
                                    Download ZIP
                                  </button>
                                </div>
                              )}
                              <a
                                href="#"
                                className="dropdown-toggle mr-n1"
                                data-toggle="dropdown"
                              >
                                <div className="user-toggle">
                                  <div
                                    style={{ fontSize: "1.1rem" }}
                                    className=" dropdown-indicator "
                                  >
                                    View{" "}
                                    <span className="text-primary">
                                      {limit}
                                    </span>
                                  </div>
                                </div>
                              </a>{" "}
                              <div className="dropdown-menu">
                                <div className="dropdown-inner">
                                  <ul
                                    onClick={handleLimitChange}
                                    className="link-list"
                                  >
                                    <li value={10}>10</li>
                                    <li value={20}>20</li>
                                    <li value={40}>40</li>
                                    <li value={100}>100</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    <div className="form-check mb-4">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={
                                          selectedOrders.length ===
                                          orders.length
                                        }
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setSelectedOrders(
                                              orders.map((order) => order._id)
                                            );
                                          } else {
                                            setSelectedOrders([]);
                                          }
                                        }}
                                      />
                                    </div>
                                  </th>
                                  <th scope="col">#</th>
                                  {/* <th scope="col">Username</th> */}
                                  <th
                                    scope="col"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    First Name
                                  </th>
                                  <th scope="col">OrderID</th>
                                  <th scope="col">From</th>
                                  <th scope="col">To</th>
                                  <th scope="col">Tracking Id</th>
                                  <th scope="col">LabelType</th>
                                  <th scope="col">Total</th>
                                  <th scope="col">Created At</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.map((order, index) => (
                                  <tr>
                                    <td>
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          checked={selectedOrders.includes(
                                            order?._id
                                          )}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setSelectedOrders([
                                                ...selectedOrders,
                                                order?._id,
                                              ]);
                                            } else {
                                              setSelectedOrders(
                                                selectedOrders.filter(
                                                  (id) => id !== order?._id
                                                )
                                              );
                                            }
                                          }}
                                          id="flexCheckDefault"
                                        />
                                      </div>
                                    </td>
                                    <td>{(page - 1) * limit + index + 1}</td>
                                    {/* <td>{order?.user?.username}</td> */}
                                    <td>{order?.user?.firstName}</td>
                                    <td>{order?.OrderId}</td>
                                    <td>{order?.fromName}</td>
                                    <td>{order?.toName}</td>
                                    <td>{order?.tracking}</td>
                                    <td>{order?.labelType.name}</td>
                                    <td>${order?.price}</td>
                                    <td>
                                      {new Date(
                                        order?.createdAt
                                      ).toLocaleString()}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-sm btn-dark"
                                        onClick={() => {
                                          downloadPdf(order?._id);
                                        }}
                                      >
                                        <em className="icon ni ni-download"></em>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="view"
        className="modal fade "
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="false"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                View Order
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title text-center">From</h5>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <span className="fw-bold">Name:</span> {order.FromName}{" "}
                        <br />
                        <span className="fw-bold">Company:</span>{" "}
                        {order.FromCompany} <br />
                        <span className="fw-bold">Street:</span>{" "}
                        {order.FromStreet} <br />
                        <span className="fw-bold">Street2:</span>{" "}
                        {order.FromStreet2} <br />
                        <span className="fw-bold">City:</span> {order.FromCity}{" "}
                        <br />
                        <span className="fw-bold">State:</span>{" "}
                        {order.FromState} <br />
                        <span className="fw-bold">Zip:</span> {order.FromZip}{" "}
                        <br />
                        <span className="fw-bold">Country:</span>{" "}
                        {order.FromCountry} <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title text-center">To</h5>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <span className="fw-bold">Name:</span> {order.ToName}{" "}
                        <br />
                        <span className="fw-bold">Company:</span>{" "}
                        {order.ToCompany} <br />
                        <span className="fw-bold">Street:</span>{" "}
                        {order.ToStreet} <br />
                        <span className="fw-bold">Street2:</span>{" "}
                        {order.ToStreet2} <br />
                        <span className="fw-bold">City:</span> {order.ToCity}{" "}
                        <br />
                        <span className="fw-bold">State:</span> {order.ToState}{" "}
                        <br />
                        <span className="fw-bold">Zip:</span> {order.ToZip}{" "}
                        <br />
                        <span className="fw-bold">Country:</span>{" "}
                        {order.ToCountry} <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title text-center">
                        Additional details
                      </h5>
                    </div>
                    <div className="card-body">
                      <div className="card-text">
                        <span className="fw-bold">Label Type:</span>{" "}
                        {order.labelType?.name} <br />
                        <span className="fw-bold">Weight:</span> {order.Weight}
                        {order.labelType?.name.includes("First Class") ? (
                          <span className="fw-bold">oz</span>
                        ) : (
                          <span className="fw-bold">lb</span>
                        )}{" "}
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-end">
                <button className="btn btn-dark me-2" title="Download">
                  <i className="bi-download"></i>
                </button>
                <button className="btn btn-dark" title="Refund">
                  <i className="bi-currency-exchange"></i>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
