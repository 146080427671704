import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { server, config } from "../env";

const Logs = (props) => {
  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("des");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [url, setUrl] = useState("/api/v1/logs/read");

  useEffect(() => {
    getLogs();
  }, [page, sort, filter, limit]);

  const getLogs = async (search) => {
    var link = "";

    if (search) {
      link =
        url +
        "?page=" +
        page +
        "&limit=" +
        limit +
        "&sort=" +
        sort +
        "&status=" +
        filter +
        "&search=" +
        search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
      // "&sort=" +
      // sort +
      // "&status=" +
      // filter;
    }

    await axios
      .get(server + link, config)
      .then((res) => {
        setLogs(res.data.logs);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Transaction Logs
                    </h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-4">
                          <div className="d-md-flex justify-content-between align-items-center">
                            {/* <div className="d-md-flex justify-content-center align-items-center ">
                              <div className="form-group ml-2 mt-3">
                                <select
                                  name="sort"
                                  id=""
                                  className="form-control"
                                  value={sort}
                                  onChange={(e) => {
                                    setSort(e.target.value);
                                  }}
                                >
                                  <option value="asc">
                                    Sort by Date Ascending
                                  </option>
                                  <option value="des">
                                    Sort by Date Descending
                                  </option>
                                </select>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">User</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Balance</th>
                                  <th scope="col">Date</th>
                                  {/* <th scope="col">Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {logs.map((log, index) => (
                                  <tr key={log._id}>
                                    <td>{index + 1}</td>
                                    <td>{log.user?.username}</td>
                                    <td>{log.type}</td>
                                    <td>$ {log.amount}</td>
                                    <td>$ {log.balance}</td>
                                    <td>
                                      {new Date(log.createdAt).toLocaleString()}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logs;
