import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server, config } from "../../env";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";
const SingleType = (props) => {
  const { id } = useParams();
  const [pricing, setPricing] = useState({});
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [singleWeight, setSingleWeight] = useState({});

  const getPricing = async () => {
    await axios
      .get(server + "/api/v1/labeltype/read/" + id, config)
      .then((res) => {
        setPricing(res.data.labelType);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getPricing();
  }, [id]);

  const addWeight = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const data = {
      fromWeight: e.target.fromWeight.value,
      toWeight: e.target.toWeight.value,
      price: e.target.price.value,
    };

    await axios
      .post(server + "/api/v1/labeltype/addweight/" + id, data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getPricing();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const deleteWeight = async (fromWeight, toWeight) => {
    const data = {
      fromWeight: fromWeight,
      toWeight: toWeight,
    };

    await axios
      .post(server + "/api/v1/labeltype/deleteWeight/" + id, data, config)
      .then((res) => {
        console.log(res);
        getPricing();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateWeight = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const data = {
      price_id: singleWeight._id,
      fromWeight: e.target.fromWeight.value,
      toWeight: e.target.toWeight.value,
      price: e.target.price.value,
    };

    await axios
      .put(server + "/api/v1/labeltype/updateWeight/" + id, data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getPricing();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <Link to="/pricing" className="btn btn-sm btn-warning">
                  <em className="icon ni ni-arrow-left"></em> Go Back
                </Link>
                <div className="nk-block-between">
                  <div className="nk-block-head-content  w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <h2 className="card-title">{pricing.name}</h2>
                      <button
                        className="btn btn-info"
                        data-toggle="modal"
                        data-target="#create"
                      >
                        <i className="bi-plus "></i> Add Weight
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-0 pb-0">
          <table className="table ">
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>From Weight</th>
                <th>To Weight</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {pricing.prices?.map((price, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{price.fromWeight}</td>
                  <td>{price.toWeight}</td>
                  <td>${price.price}</td>
                  <td>
                    <button
                      className="btn btn-primary mr-2"
                      data-toggle="modal"
                      data-target="#edit"
                      onClick={() => setSingleWeight(price)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() =>
                        deleteWeight(price.fromWeight, price.toWeight)
                      }
                    >
                      <i className="bi-trash-fill "></i>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div
        id="create"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add weight
              </h5>
              <button
                type="button"
                className=" btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={addWeight}>
                <div className="form-group mb-3">
                  <label>From Weight</label>
                  <input
                    type="number"
                    className="form-control"
                    name="fromWeight"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>To Weight</label>
                  <input
                    type="number"
                    className="form-control"
                    name="toWeight"
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Price</label>
                  <input
                    type="number"
                    className="form-control"
                    name="price"
                    step={0.01}
                    required
                  />
                </div>
                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Add {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        id="edit"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Edit weight
              </h5>
              <button
                type="button"
                className=" btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={updateWeight}>
                <div className="form-group mb-3">
                  <label>From Weight</label>
                  <input
                    type="number"
                    className="form-control"
                    name="fromWeight"
                    required
                    defaultValue={singleWeight.fromWeight}
                  />
                </div>
                <div className="form-group mb-3">
                  <label>To Weight</label>
                  <input
                    type="number"
                    className="form-control"
                    name="toWeight"
                    required
                    defaultValue={singleWeight.toWeight}
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Price</label>
                  <input
                    type="number"
                    className="form-control"
                    name="price"
                    step={0.01}
                    defaultValue={singleWeight.price}
                    required
                  />
                </div>

                <div className="d-flex mt-2 justify-content-end ">
                  <button
                    type="button"
                    className="btn btn-white me-3"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleType;
