import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { Link } from "react-router-dom";

const UsersApproval = (props) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [url, setUrl] = useState("/api/v1/users/read-non-approved");

  const getUsers = async (search) => {
    var link = "";
    if (search) {
      link = url + "?page=" + page + "&limit=" + limit + "&search=" + search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
    }

    await axios
      .get(server + link, config)
      .then((res) => {
        setUsers(res.data.users);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveUser = async (user) => {
    await axios
      .put(server + "/api/v1/users/approve/" + user._id, {}, config)
      .then((res) => {
        getUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUsers();
  }, [page, limit]);

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Users</h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-lg-4 ">
                          <div className="d-flex flex-column flex-lg-row justify-content-between  align-items-center">
                            {/* .search */}
                            <div className="col-lg-4 col-12 mt-3">
                              <div className="form-group mr-3 ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  onChange={(e) => {
                                    getUsers(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  {/* <th>Username</th> */}
                                  <th>FirstName</th>
                                  <th>LastName</th>
                                  <th>Chat ID</th>
                                  <th>Balance</th>
                                  <th>ApiKey</th>
                                  <th>Joined</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {users.map((user, index) => (
                                  <tr key={index}>
                                    <td>
                                      {page > 1
                                        ? (page - 1) * 10 + index + 1
                                        : index + 1}
                                    </td>
                                    {/* <td>{user.username}</td> */}
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.chatId}</td>
                                    <td>{"$ " + user.balance}</td>
                                    <td>{user.apiKey}</td>
                                    {/* <td>
                                      {user.status ? (
                                        <span className="badge badge-success">
                                          Active
                                        </span>
                                      ) : (
                                        <span className="badge badge-danger">
                                          Inactive
                                        </span>
                                      )}
                                    </td> */}
                                    <td>
                                      {new Date(
                                        user.createdAt
                                      ).toLocaleDateString()}
                                    </td>

                                    <td>
                                      <button
                                        className="btn btn-success mr-2"
                                        onClick={() => {
                                          approveUser(user);
                                        }}
                                      >
                                        Enable User
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersApproval;
