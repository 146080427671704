import React from "react";
import Header from "../components/Header";
import axios from "axios";
import Loader from "../components/Loader";
import { server, config } from "../env";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Website = (props) => {
  const [cashapp, setCashapp] = useState({});
  const [loader, setLoader] = useState("");
  const [topupLoader, setTopupLoader] = useState("");
  const [bitcoin, setBitcoin] = useState({});
  const [litecoin, setLitecoin] = useState({});
  const [amount, setAmount] = useState("");
  const [balanceData, setBalanceData] = useState({});

  const readCashapp = async () => {
    await axios
      .get(server + "/api/v1/website/get-cashapp", config)
      .then((res) => {
        setCashapp(res.data.cashapp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    readCashapp();
    checkBalance();
  }, []);

  const updateCashApp = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = new FormData();
    params.append("cashappId", e.target.cashappId.value);
    params.append("image", e.target.qr.files[0]);

    await axios
      .put(server + "/api/v1/website/update-cashapp", params, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        readCashapp();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const restartBot = async () => {
    await axios.post(server + "/api/v1/admin/restart-bot", {}, config);
  };

  const topup = async (e) => {
    e.preventDefault();
    setTopupLoader(<Loader />);

    const params = {
      amount: e.target.amount.value,
    };

    await axios
      .post(server + "/api/v1/website/topup", params, config)
      .then((res) => {
        setTopupLoader("");
        toast.success(res.data.message);
        setBitcoin(res.data.data.btc);
        setLitecoin(res.data.data.ltc);
        setAmount(res.data.data.amount);
      })
      .catch((err) => {
        setTopupLoader("");
        toast.error(err.response.data.message);
      });
  };

  const checkBalance = async () => {
    await axios.get(server + "/api/v1/website/balance", config).then((res) => {
      setBalanceData(res.data.data);
    });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Website Settings
                    </h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row mb-3">
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">Topup</h5>
                      </div>

                      <div className="card-body">
                        <form onSubmit={topup}>
                          <div className="row p-2">
                            <div className="col">
                              {/* show current balance */}

                              <div className="row form-group">
                                <label className="form-label">
                                  Current Balance
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={"$" + balanceData.balance}
                                  readOnly
                                />
                              </div>

                              <div className="row form-group">
                                <label className="form-label">Topup</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="amount"
                                  placeholder="Enter amount"
                                  name="amount"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row p-3">
                            <button type="submit" className="btn btn-primary">
                              Topup {topupLoader}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  {bitcoin.address && (
                    <div className="col-lg-3">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title">Bitcoin</h5>
                        </div>
                        <div className="card-body">
                          <div className="row p-2">
                            <div className="col">
                              <div className="row form-group">
                                <label className="form-label">Address</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  name="address"
                                  value={bitcoin.address}
                                  readOnly
                                />
                              </div>

                              <div className="row form-group">
                                <label className="form-label">Amount</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="amount"
                                  name="amount"
                                  value={bitcoin.amount}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {litecoin.address && (
                    <div className="col-lg-3">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title">Litecoin</h5>
                        </div>
                        <div className="card-body">
                          <div className="row p-2">
                            <div className="col">
                              <div className="row form-group">
                                <label className="form-label">Address</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  name="address"
                                  value={litecoin.address}
                                  readOnly
                                />
                              </div>

                              <div className="row form-group">
                                <label className="form-label">Amount</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="amount"
                                  name="amount"
                                  value={litecoin.amount}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">Cashapp</h5>
                      </div>

                      <div className="card-body">
                        <form onSubmit={updateCashApp}>
                          <div className="row p-2">
                            <div className="col">
                              <div className="row form-group">
                                <label htmlFor="oldPassword">Cashapp ID</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cashappId"
                                  placeholder="Enter cashapp ID"
                                  name="cashappId"
                                  value={cashapp.cashappId}
                                  onChange={(e) =>
                                    setCashapp({
                                      ...cashapp,
                                      cashappId: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="row form-group">
                                <label htmlFor="newPassword">QR code</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  id="qr"
                                  name="qr"
                                />
                              </div>
                              {cashapp.qr && (
                                <div className="row   d-sm-flex justify-content-center align-items-center">
                                  {/* show image */}
                                  <div className="form-group">
                                    <img
                                      src={server + "/" + cashapp.qr}
                                      alt="cashapp"
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row p-3">
                            <button type="submit" className="btn btn-primary">
                              Update Cashapp Credentials {loader}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {/* restart bot */}
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title">Restart Bot</h5>
                      </div>

                      <div className="card-body">
                        <button
                          className="btn btn-primary"
                          onClick={restartBot}
                        >
                          Restart Bot
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Website;
